import ReactPlayer from "react-player";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import FsLightbox from "fslightbox-react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import JWPlayer from "@jwplayer/jwplayer-react";
import ReactJWPlayer from "react-jw-player";
import { Helmet } from "react-helmet";
import { FcExpand } from "react-icons/fc";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { FcPrevious } from "react-icons/fc";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import CryptoJS from "crypto-js";
import getGeneralContent, { getGeneralContentBySlug } from "../../../Services/generalContent";
import { MainSliderApi } from "../../../Services/allSliders";
import Comments from "../../../components/Comments";
import AddComment from "../../../components/AddComment";
import { SuggestedApi, UpComingSliderApi } from "../../../Services/SliderApi";
// import { Player, ControlBar } from "video-react";
import { addToHistory, addToFavorites } from "../../../Services/addToHistory";
import { error } from "jquery";
// import VideoPlayer from "../../../components/VideoPlayer";
import postLoginData from "../../../Services/postLoginData";
import postSignUpData from "../../../Services/postSignUpData";
import sendEmailVerification from "../../../Services/sendEmailVerification";
import verifyEmailCode from "../../../Services/verifiyEmailCode";
import Stripe from "../pages/auth/stripe";
import logo from "../../../assets/images/logo.png";
import PaymentPage from "../../../components/PaymentPage";
import MoviePoster from "../../../components/moviePoster";
import {
  assignAdToTarget,
  convertSecondsToHoursMinutes,
  dateToWords,
  getDate,
  handleAdClicked,
  handleAnchorClick,
  limitTextToWords,
  shuffleArray,
} from "../../../Services/generalFunctions/generalFunctions";
import LockPoster from "../../../components/lockPoster";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  handleConfirmDeleteFAV,
  handleConfirmDeleteWatchList,
} from "../home/FavWatch";
import { MoviesByGenre } from "../../../Services/moviesApi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import checkUserCard from "../../../Services/checkUserCard";
import updateUserDetails from "../../../Services/updateUserDetails";
import AuthSession from "../../../Services/getSessionAuth";
import VideoPoster from "../../../components/VideoPoster";
import {
  VideoFbShareUrl,
  VideoTwitterShareUrl,
  VideoWhatsappShareUrl,
  generateFacebookShareUrl,
  generateInstagramShareUrl,
  generateLinkedinShareUrl,
  generatePinterestShareUrl,
  generateQuoraShareUrl,
  generateRedditShareUrl,
  generateSnapchatShareUrl,
  generateTelegramShareUrl,
  generateTikTokShareUrl,
  generateTwitterShareUrl,
  generateWhatsappShareUrl,
  genreFacebookShareUrl,
  genreInstagramShareUrl,
  genreLinkedinShareUrl,
  genrePinterestShareUrl,
  genreQuoraShareUrl,
  genreRedditShareUrl,
  genreSnapchatShareUrl,
  genreTelegramShareUrl,
  genreTikTokShareUrl,
  genreTwitterShareUrl,
  genreWhatsappShareUrl,
} from "../home/ShareFunction";
import userSubscription from "../../../Services/userSubscription";
import getAnalytics from "../../../Services/getAnalytics";
import videoCharge from "../../../Services/exclusiveVideoCharge";
import userPaid from "../../../Services/userPaid";
import LoginSignupModal from "../../../components/LoginSignupModal";
import VideoDescriptionModal from "../../../components/partials/UI/VideoDescriptionModal";
import { fetchUserData } from "../../../Services/bankDetails";
import { adClickApi, fetchAdsDataVideo } from "../../../Services/adsApi";
import AdsModal from "../../../components/partials/UI/AdsModal";
import CopyLinkButton from './../../../components/partials/UI/CopyLinkButton';
import Loginsignup from "../pages/auth/login_signup";

// install Swiper modules
SwiperCore.use([Navigation]);

const VideoDetails = () => {
  const [content, setContent] = useState({});
  const [playerId, setPlayerId] = useState("");
  const [toggler1, setToggler1] = useState(false);
  const [pricingModal, setPricingModal] = useState(false);
  const [activeBtn, setActiveBtn] = useState("description");
  const [loader, setLoader] = useState(true);
  const [genre, setGenre] = useState([]);
  const [loginModal, setLoginModal] = useState(false);
  const [checkout, setCheckout] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isWatchListClicked, setIsWatchListClicked] = useState(false);
  const currentUserToken = localStorage.getItem("token");
  const [videosGenre, setVideosGenre] = useState([]);
  const lang = localStorage.getItem("lang");
  const history = useHistory();
  const location = useLocation();
  const isInitialRender = useRef(true);
  const [expirationDate, setExpirationDate] = useState("");
  const [id, setId] = useState("");

  const isPrerender = () => {
    return typeof navigator !== 'undefined' && navigator.userAgent === 'ReactSnap';
  };

  // useEffect(() => {
  //   if (
  //     !isInitialRender.current &&
  //     lang &&
  //     location.pathname.includes("/video-details/app/")
  //   ) {
  //     history.push("/");
  //   }
  //   isInitialRender.current = false;
  // }, [lang, history, location]);

  useEffect(() => {
    MoviesByGenre(lang)
      .then((data) => {
        setVideosGenre(data?.upcomingContent);
      })
      .catch((error) => {
        // console.log(error, "videos By Genre Slider error");
      });
  }, [lang]);

  // to handle the checkout modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setShowStripeCard(false);
    setCardInfo(false);
  };
  const handleShow = () => setShow(true);

  // to handle trailer modal
  const [showTrailerModal, setShowTrailerModal] = useState(false);
  const handleShowTrailerModal = () => setShowTrailerModal(true);
  const handleCloseTrailerModal = () => setShowTrailerModal(false);

  const [updateDataAfterUpdateDuration, setUpdateDataAfterUpdateDuration] = useState(false);

  const getMediaInfo = async (mediaId, sourceMediaId) => {


    try {

      console.log("getting media info ")
      const response = await axios.get(`${process.env.REACT_APP_API}/api/admin/get-info-jwplayer-api/${mediaId}`);
      console.log(response);
  
      if (response.status === 200) {
        // set loading false
        // setIsUploading(false);

        const data = {
          duration : response.data.duration,
          mediaId : sourceMediaId
        }
        await updateMediaField(data)
        return response.data;

      } else {
        return false;
      }
    } catch (error) {
      console.error('Error while getting media info:', error);
      return false;
    }
    
  };


  const updateMediaField = async (data) => {
    
    var bodyData = {
      duration: data.duration,
    };

    console.log(bodyData);
      var apiResponse = await axios
        .put(
          `${process.env.REACT_APP_API}/api/media/upload-media-id/${data.mediaId}`,
          bodyData,
          {
            headers: {
              Authorization: `Bearer ${currentUserToken}`,
              source: "administer",
            },
          }
        )
        // onMediaUpload needed in redux state
        .then(async (onMediaUpload) => {
          console.log(onMediaUpload);
          setUpdateDataAfterUpdateDuration(true);
        })
        .catch(async (onMediaUploadError) => {
          console.log(onMediaUploadError);
        });
  }


  // to handle the login modal
  const [showLoginModal, setShowLoginModal] = useState(false);
  const handleCloseLoginModal = () => setShowLoginModal(false);
  const handleShowLoginModal = () => setShowLoginModal(true);

  const { slug, category } = useParams(); //generalObject ID
  const currentUser = JSON.parse(localStorage.getItem("session"));

  const currentUserId = currentUser?._id;
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    });

    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
  };
  const [favoriteItems, setFavoriteItems] = useLocalStorage(
    "favoriteItems",
    []
  );
  const [watchListItems, setWatchListItems] = useLocalStorage(
    "watchListItems",
    []
  );


  // useEffect(() => {
  //   if (
  //     !isInitialRender.current &&
  //     lang &&
  //     location.pathname.includes("/video-details/")
  //   ) {
  //     history.push("/");
  //   }
  //   isInitialRender.current = false;
  // }, [lang, history, location]);

  function handleFavClick(event, id, category) {
    event.preventDefault();
    if (!currentUserId || currentUserId === "") {
      // Navigate to the login page
      history.push("/login");
      return;
    }

    const url = `${process.env.REACT_APP_API}/api/user/add-to-favorite/${currentUserId}`;
    const data = {
      general_content_id: id,
      category: mediaCategory,
    };

    // Check if the item is already in the favorite list
    if (favoriteItems.includes(id)) {
      // Remove the item from the favorite list
      handleConfirmDeleteFAV(id);
      toast.info(t("Content Removed From Favorite list!"), {
        position: "top-center",
      });
      setFavoriteItems(favoriteItems.filter((item) => item !== id));
    } else {
      // Add the item to the favorite list
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUserToken}`,
          source: "front",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            toast.success(t("Content added to Favorite list!"), {
              position: "top-center",
            });
            setFavoriteItems([...favoriteItems, id]);
          } else {
            toast.error(t("Error occurred at Fav List"));
            throw new Error("Something went wrong...");
          }
        })
        .catch((error) => {
          // console.error(error);
        });
    }
  }
  function handleWatchList(event, id, category) {
    event.preventDefault();
    if (!currentUserId || currentUserId === "") {
      // Navigate to the login page
      history.push("/login");
      return;
    }

    const url = `${process.env.REACT_APP_API}/api/user/add-to-watch-list/${currentUserId}`;
    const data = {
      general_content_id: id,
      category: mediaCategory,
    };

    // Check if the item is already in the watch list
    if (watchListItems.includes(id)) {
      // Remove the item from the watch list
      handleConfirmDeleteWatchList(id);
      toast.info(t("Content Removed From Watch list!"), {
        position: "top-center",
      });
      setWatchListItems(watchListItems.filter((item) => item !== id));
    } else {
      // Add the item to the watch list
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUserToken}`,
          source: "front",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            toast.success(t("Content added to Watch list!"), {
              position: "top-center",
            });
            setWatchListItems([...watchListItems, id]);
          } else {
            toast.error(t("Error occurred at Watch List"));
          }
        })
        .catch((error) => {
          // console.error(error);
        });
    }
  }

  useEffect(() => {
    if (!currentUserId || currentUserId === "") {
      // Clear the favorite and watchlist items
      setFavoriteItems([]);
      setWatchListItems([]);
      return;
    }

    const fetchData = async (url, setData) => {
      try {
        const response = await axios.get(url);
        const data = response?.data?.map((item) => item?.general_content_id);
        setData(data);
      } catch (error) {
        // console.log(`Error fetching data: ${error}`);
      }
    };

    // if (currentUserId) {
    const watchListUrl = `${process.env.REACT_APP_API}/api/user/watch-list-items/${currentUserId}`;
    fetchData(watchListUrl, setWatchListItems);

    const favoriteItemsUrl = `${process.env.REACT_APP_API}/api/user/favorite-items/${currentUserId}`;
    fetchData(favoriteItemsUrl, setFavoriteItems);
    // }
  }, [currentUserId]);
  // const currentUserId = false;
  const { source } = useParams(); // source name
  const [commentFlag, setCommentFlag] = useState(false);
  // content details

  const media_Id = content?.generalContentObj?.media?._id;
  const mediaId = content?.generalContentObj?.media?.media_id;
  const trailerId = content?.generalContentObj?.trailer?.media_id;
  const bannerImg = content?.generalContentObj?.thumbnail?.banner_thumbnail_url;
  const generalContentId = content?.generalContentObj?._id
  const currentSelectedLanguage = localStorage.getItem("lang") || "fr";


  let movieTitle = content?.generalContentObj?.media?.title;

  let movieShortLink = content?.generalContentObj?.shortVideoLink;
  

  if(currentSelectedLanguage !== "fr"){
     movieTitle = content?.generalContentObj?.media?.translated_content?.find(e =>  e.language_code === "en")?.title_translated;
   }

  const releaseDate = content?.generalContentObj?.media?.release_year;


  let mediaDesc = content?.generalContentObj?.media?.description;
  if(currentSelectedLanguage !== "fr"){
   mediaDesc = content?.generalContentObj?.media?.translated_content?.find(e =>  e.language_code === "en")?.description_translated;
  }






  const duration = content?.generalContentObj?.media?.duration;
  const genreName = content?.generalContentObj?.genre?.name;
  const iDHY = content?.generalContentObj?._id;
  const jwTags = content?.generalContentObj?.media?.jw_tags;
  const mediaCategory = content?.generalContentObj?.category;
  const crewMembers = content?.generalContentObj?.crew_members;
  const crewNames = crewMembers?.map((member) => member.member_name);
  const crewRoles = crewMembers?.map((member) => member.member_role);
  const contentType = content?.generalContentObj?.content_type;
  const price = content?.generalContentObj?.price;
  // const contentType = "paid";
  const epID = content?.generalContentObj?._id;
  const availability = content?.generalContentObj?.availability;

  const [likeCount, setLikeCount] = useState(() => {
    const storedLikeCount = localStorage.getItem("likeCount");
    return storedLikeCount ? Number(storedLikeCount) : 0;
  });
  const [isLiked, setIsLiked] = useState(false);
  const [likeId, setLikeId] = useState("");

  const fetchLikeCount = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/likes/like-general-content/${media_Id}`
      );
      if (response.status === 200) {
        if (response.data?.noOfLikes) {
          const { noOfLikes } = response.data;
          setLikeCount(Number(noOfLikes));
        }
      }
    } catch (error) {
      // console.error("Error fetching like count:", error);
    }
  }, [media_Id, setLikeCount]);
  useEffect(() => {
    const storedLikeCount = localStorage.getItem("likeCount");
    const storedLikeStatus = localStorage.getItem("isLiked");
    const storedLikeId = localStorage.getItem("likeId");

    const expirationDateY = new Date(currentUser?.subscription_date);
    expirationDateY.setMonth(expirationDateY.getMonth() + 1);
    setExpirationDate(expirationDateY)

    if (storedLikeCount) {
      setLikeCount(Number(storedLikeCount));
    }

    if (storedLikeStatus) {
      setIsLiked(storedLikeStatus === "true");
    }

    if (storedLikeId) {
      setLikeId(storedLikeId);
    }

    checkLikeStatus();
  }, []);

  // const handleLike = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API}/api/likes/like-general-content`,
  //       {
  //         general_content_id: id,
  //         media_obj_id: media_Id,
  //         user_id: currentUserId,
  //         category: mediaCategory,
  //       }
  //     );
  //     console.log(response, "response of Like");
  //     if (response.status === 200) {
  //       setIsLikeClicked(true);
  //       setLikeCount((prevCount) => prevCount + 1);
  //       toast.success("Item Liked!", {
  //         position: "top-center",
  //       });
  //     } else {
  //       toast.error("Error occurred at Like");
  //     }
  //     console.log(response.data); // Handle the response as needed
  //   } catch (error) {
  //     console.error(error, "error at like api"); // Handle the error as needed
  //   }
  // };

  // useEffect(() => {
  //   localStorage.setItem('likeCount', likeCount.toString());
  // }, [likeCount]);

  useEffect(() => {
    localStorage.setItem("likeCount", likeCount.toString());
    localStorage.setItem("isLiked", isLiked.toString());
    localStorage.setItem("likeId", likeId);
  }, [likeCount, isLiked, likeId]);
  const checkLikeStatus = useCallback(async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/likes/check-like-general-content`,
        {
          media_obj_id: media_Id,
          user_id: currentUserId,
          category: mediaCategory,
        }
      );
      if (response.status === 200) {
        if (response?.data?.like?._id) {
          setIsLiked(true);
          setLikeId(response?.data?.like?._id);
        } else {
          setIsLiked(false);
          setLikeId("");
        }
      }
    } catch (error) {
      // console.error(error, "Error checking like status");
    }
  }, [media_Id, currentUserId, mediaCategory]);

  const handleLike = async () => {
    if (currentUserId) {
      try {
        if (isLiked) {
          await dislikeContent();
        } else {
          await likeContent();
        
        }

        // Fetch updated like count
        fetchLikeCount();
      } catch (error) {
        toast.error(t("Error occurred while liking/disliking"), {
          position: "top-center",
        });
      }
    } else {
      // Navigate to the login page
      history.push("/login");
    }
  };

  const likeContent = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/likes/like-general-content`,
        {
          general_content_id: id,
          media_obj_id: media_Id,
          user_id: currentUserId,
          category: mediaCategory,
        }
      );
      if (response.status === 200 && response.data.savedLike) {
        setLikeId(response?.data?.savedLike?._id);
        setLikeId(response?.data?.savedLike?._id);
        toast.success(t("Video Liked!"), {
          position: "top-center",
        });
        setLikeCount((prevCount) => prevCount + 1);
        setIsLiked(true);
      }else{
        toast.success(t("Item Disliked!"), {
          position: "top-center",
        });
      }
    } catch (error) { }
  };
  const dislikeContent = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API}/api/likes/dislike-content/${likeId}`
      );
      setLikeId("");
      setIsLiked(false);
      toast.info(t("Item Disliked!"), {
        position: "top-center",
      });
      setLikeCount((prevCount) => prevCount - 1);
    } catch (error) {
      toast.error(t("Error occurred while disliking content"));
      throw error;
    }
  };

  useEffect(() => {
    fetchLikeCount();
  }, [fetchLikeCount]);
  const pipMode = true;

  const [blobUrl, setBlobUrl] = useState("");
  const videoUrl = "https://cdn.jwplayer.com/manifests/y1bKg18m.m3u8";
  useEffect(() => {
    const fetchVideo = async () => {
      const response = await fetch(videoUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setBlobUrl(url);
    };

    fetchVideo();
  }, [videoUrl]);

  // console.log("n", blobUrl);
  // for signed urls jwt token ===============================================================
  // function generateSignedUrl(path, host = "https://cdn.jwplayer.com") {
  //   const time = new Date();
  //   const expiration = Math.ceil((time.getTime() + 3600) / 300) * 300;
  //   const signature = CryptoJS.HmacSHA256(
  //     `${path}${expiration}`,
  //     API_SECRET
  //   ).toString();

  //   return `${host}${path}?expires=${expiration}&signature=${signature}`;
  // }
  // login - signup===============================================================================
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // switch language
  const { t } = useTranslation();

  const [isSignUpActive, setIsSignUpActive] = useState(false);

  const handleSignUpClick = () => {
    setIsSignUpActive(true);
  };

  const handleSignInClick = () => {
    setIsSignUpActive(false);
  };

  // get the page number
  const [page, setPage] = useState(0);
  const [subscriptionForm, setSubscriptionForm] = useState(false);
  const [otp, setOtp] = useState(false);

  // cards
  const [activeCard, setActiveCard] = useState(true);
  const handleCardClick = (cardIndex) => {
    setActiveCard(cardIndex);
  };

  // login form data
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [emptyObj, setEmptyObj] = useState(false);
  const [loginInput, setLoginInput] = useState({});
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginInput({ ...loginInput, [name]: value });
  };

  const isValidEmail = (email) => {
    // regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isValidPassword = (password) => {
    const hasSpecialCharacter = /[!@#$%^&(),.?":{}|<>]/.test(password);
    const hasMinimumLength = password.length >= 8;
    const hasNumber = /\d/.test(password);

    return hasSpecialCharacter && hasMinimumLength && hasNumber;
  };

  const loginSubmit = async (event) => {
    event.preventDefault();
    if (!loginInput.email || !loginInput.password) {
      setEmptyObj(true);
      setTimeout(() => {
        setEmptyObj(false);
      }, 3000);
      return;
    }

    if (!isValidEmail(loginInput.email)) {
      setError("Invalid email address");
      setShowLoginError(true);
      return;
    }
    postLoginData(loginInput, setShowLoginError, history, setLoginError, "");

    // setShowLoginModal(false);
  };

  // signup form data
  const [otpValue, setOtpValue] = useState("");
  // form data
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    subscription: "free",
    //  otp: "",
  });
  const handleOtpChange = (otp) => {
    setOtpValue(otp);
    //  setFormData({ ...formData, otp: otp }); // Update the formData object with the new OTP value
  };
  //
  //  this is for handling the signup data (works on validate button) - giving it break for a while for email verification code api
  const [userExistError, setuserExistError] = useState("");
  // const [showError, setShowError] = useState(false);
  const handleSignUp = async (event) => {
    postSignUpData(
      formData,
      history,
      setuserExistError,
      setShowError,
      setShowLoginError,
      setLoginError,
      ""
    );
  };

  // handle user exist on next button of signup form
  // const handleSignUpNext = () ={

  // }

  // SIGN UP : EMAIL VERIFICATION ==================================================================================
  const obj = {
    subject: "email verification",
    email: formData.email,
  };
  // when user clicks on next button send the email on emailverficiation api
  const handleEmailVerification = () => {
    if (!isValidEmail(formData.email)) {
      setError("Invalid email address");
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
      return;
    }
    if (!isValidPassword(formData.password)) {
      let errorMessage = "Password must contain ";
      if (formData.password.length < 8) {
        errorMessage += "at least 8 characters";
        setError(errorMessage);
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 3000);
        return;
      }
      if (!/[!@#$%^&(),.?":{}|<>]/.test(formData.password)) {
        errorMessage += "at least one special character";
        setError(errorMessage);
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 3000);
        return;
      }
      if (!/\d/.test(formData.password)) {
        errorMessage += "at least one number";
        setError(errorMessage);
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 3000);
        return;
      }
    }
    // if (!isValidPassword(formData.password)) {
    //   setError(
    //     "Password must contain at least 8 characters, one special character, and one number"
    //   );
    //   setShowError(true);
    //   setTimeout(() => {
    //     setShowError(false);
    //   }, 3000);
    //   return;
    // }
    sendEmailVerification(obj);
    setPage(page + 1);
    setSubscriptionForm(true);
  };
  // when user enters the received code validate the code using email code and token from email verification API
  const verifyCode = {
    email: formData.email,
    code: otpValue,
    token: localStorage.getItem("email verification token"),
  };
  //  used to show error on otp page
  const [wrongEmailCode, setWrongEmailCode] = useState(false);
  const handleEmailCodeVerification = () => {
    verifyEmailCode(verifyCode)
      .then((result) => {
        if (result) {
          localStorage.removeItem("email verification token");
          handleSignUp();
          // history.push("/");
        } else {
          setWrongEmailCode(true);
          setTimeout(() => {
            setWrongEmailCode(false);
          }, 3000);
        }
      })
      .catch((error) => { });
  };
  // stripe state
  const [showStripe, setShowStripe] = useState(false);
  // LOGIN AND SIGN UP STATES AND FUNCTIONS ------------------------------------------------

  // if user is not logged in then set login modal to true
  // if (!currentUserId) {
  //   setLoginModal(true);
  // }
  const video = {
    sourceUrl: "https://cdn.jwplayer.com/manifests/EXAMPLE.m3u8",
  };

  const handleClick = (btnText) => {
    setActiveBtn(btnText);
  };


  //revenir ici
  useEffect(() => {
    const url = `${process.env.REACT_APP_API}/api/analytics/add-page-count`;

    // Get the data from localStorage and parse it as an object
    const storedData = JSON.parse(localStorage.getItem("apiData")) || {};

    // Get the current timestamp
    const currentTimestamp = new Date().getTime();

    // Check if the API was already called within the last 5 minutes for the current general_content_id
    if (storedData[id] && currentTimestamp - storedData[id] < 60 * 60 * 1000) {
      return;
    }

    // Call the API and update the data in localStorage
    const payload = {
      general_content_id: id,
      type: "app",
      category: mediaCategory,
    };

    axios
      .post(url, payload)
      .then((response) => {
        // Update the data in localStorage
        const updatedData = {
          ...storedData,
          [id]: currentTimestamp,
        };
        localStorage.setItem("apiData", JSON.stringify(updatedData));
      })
      .catch((error) => { });
  }, []);

  // const ids = mainSliderData
  //   .map((obj) =>
  //     obj.general_content.media._id ? obj.general_content.media._id : null
  //   )
  //   .filter((_id) => _id !== null);
  // console.log("ids", ids);
  useEffect(() => {
    getGeneralContentBySlug({ slug })
      .then((data) => {
        console.log(data);

        setId(data?._id)
     
        if(data?.generalContentObj?.media?.duration === 0){
          getMediaInfo(data?.generalContentObj.media?.media_id, data?.generalContentObj?.media?._id);
        }
        

        setContent(data);

        setLoader(false);
        // console.log("content", content?.generalContentObj);
        // to fix the infinite rendering
        // console.log(content);
        setGenre((prevGenre) => data?.generalContentObj?.genre || prevGenre);
      })
      .catch((error) => {
        // console.log(error, "TV GenreData Slider error");
      });
  }, [updateDataAfterUpdateDuration]);

  const monetizationCheck = content?.generalContentObj?.media?.monetization; // check monetization
  useEffect(() => {
    if (monetizationCheck === true) {
      setPlayerId("4t00MwmP");
    } else {
      setPlayerId("rGoke68A");
    }
  }, [monetizationCheck]);

  // add to history api call
  useEffect(() => {
    const sendHistory = async () => {
      const obj = {
        general_content_id: media_Id,
        category: mediaCategory,
      };

      addToHistory(obj, currentUserId);
    };

    // conditions on wich history api will be called
    const isFreeContent = contentType === "free";
    const isSubscriptionPaid =
      contentType === "subscription" && currentUser?.paid === true;
    const isExclusiveContent =
      contentType === "exclusive" && hasUserPaid === true;
    const isConditionsMatched =
      isFreeContent || isSubscriptionPaid || isExclusiveContent;

    // Check if the conditions are met and make the API call
    if (isConditionsMatched && currentUserId !== "") {
      sendHistory();
    }
  }, [contentType, currentUserId]);

  // DO NOT REMOVE THE BELOW COMMENTED CODE

  // disable the right click
  // useEffect(() => {
  //   // define a custom handler function
  //   // for the contextmenu event
  //   const handleContextMenu = (e) => {
  //     // prevent the right-click menu from appearing
  //     e.preventDefault();
  //   };

  //   // attach the event listener to
  //   // the document object
  //   document.addEventListener("contextmenu", handleContextMenu);

  //   // clean up the event listener when
  //   // the component unmounts
  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //   };
  // }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Disable Ctrl+Shift+I
      if (
        (event.ctrlKey &&
          event.shiftKey &&
          (event.key === "I" || event.key === "i")) ||
        event.key === "F12"
      ) {
        event.preventDefault();
      }
    };

    // Attach event listener to disable the shortcut key
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      // Cleanup: remove event listener
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  let h = window.innerHeight;
  let w = window.innerWidth;
  window.onresize = () => {
    if (h !== window.innerHeight || w !== window.innerWidth) {
      console.log("original", w);
      console.log("after", window.innerWidth);
      if (window.innerWidth <= w / 2) {
        alert("The new width is half or less than half of the old width!");
      }
    }
  };
  // useEffect(() => {
  //   const checkConsole = () => {
  //     console.log(
  //       Object.defineProperties(new Error(), {
  //         toString: {
  //           value() {
  //             new Error().stack.includes("toString@") &&
  //               alert("Safari devtools");
  //           },
  //         },
  //         message: {
  //           get() {
  //             window.location.href = "/";
  //           },
  //         },
  //       })
  //     );
  //   };

  //   checkConsole();
  // }, []);

  const [movies, setMovies] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       for (let i = 0; i < genre.length; i++) {
  //         const genreId = genre[i]._id;
  //         const response = await axios.get(
  //           `${process.env.REACT_APP_API}/api/general-content/get-genre-movies/${genreId}/en`
  //         );
  //         const moviesData = response.data;
  //         setMovies((prevMovies) => [...prevMovies, moviesData]);
  //         console.log(moviesData);
  //         console.log(genreId, "id");
  //       }
  //     } catch (error) {
  //       console.error('Error fetching movies:', error.response.data);
  //     }
  //   };

  //   fetchData();
  // }, [genre]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let concatenatedMovies = [];
        for (let i = 0; i < genre.length; i++) {
          const genreId = genre[i]._id;
          const response = await axios.get(
            `${process.env.REACT_APP_API}/api/general-content/get-genre-movies/${genreId}/en`
          );
          const moviesData = response.data;
          concatenatedMovies = concatenatedMovies.concat(
            moviesData.upcomingContent
          );
        }
        setMovies(concatenatedMovies);
      } catch (error) {
        // console.error("Error fetching movies:", error.response.data);
      }
    };

    fetchData();
  }, [genre]);

  // merge ads in similar like this

  // get ads data
  const [adsData, setAdsData] = useState({});
  useEffect(() => {
    // Call the fetchAdsDataTvShow function
    const fetchData = async () => {
      try {
        const data = await fetchAdsDataVideo(lang);
        setAdsData(data?.adsList);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [adsCopyCopy, setAdsCopyCopy] = useState([]);
  const [moreLikeThisCopy, setMoreLikeThisCopy] = useState([]);

  useEffect(() => {

    const adsCopyCopyTemp = adsData.length > 0 ? [...adsData] : [];
    const moreLikeThisCopyTemp = [...movies];

    // Assign ads from the adsCopyCopy to topRatedDataCopy and upComingDataCopy
    assignAdToTarget(adsCopyCopy, moreLikeThisCopyTemp);
    assignAdToTarget(adsCopyCopy, moreLikeThisCopyTemp);

    // Update the state variables to reflect the changes
    setAdsCopyCopy(shuffleArray(adsCopyCopyTemp));
    setMoreLikeThisCopy(shuffleArray(moreLikeThisCopyTemp));

  }, [adsData, movies]);

  // subscription modal------------------
  const getSessionData = () => {
    const userDetails_Session = JSON.parse(localStorage.getItem("session"));
    if (userDetails_Session) {
      return {
        _id: userDetails_Session._id,
        subscription: userDetails_Session.subscription,
      };
    } else {
      return null;
    }
  };

  const [userDetails, setUserDetails] = useState(getSessionData());
  const handleUpdateData = (data) => {
    setUserDetails(data);
  };
  // stripe
  const [showStripeCard, setShowStripeCard] = useState(false);
  const [cardInfo, setCardInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (event) => {
    setLoading(true);
    try {
      const response = await checkUserCard(currentUserId);
      if (response.data.connected) {
        // console.log("response.data.connected", response.data.connected);
        userSubscription(currentUserId);
        await updateUserDetails({
          _id: currentUserId,
          subscription: "paid",
        });
        setShowStripeCard(true);
        setCardInfo(true);
        await AuthSession();
        const updatedUserDetails = getSessionData();
        setUserDetails(updatedUserDetails);
      } else {
        setShowStripeCard(true);
        setCardInfo(false);
      }
    } catch (error) {
      // console.log(error);
      // Handle the error as needed
    }

    setLoading(false);
  };
  function formatViewCount(viewCount) {
    if (viewCount >= 1000000) {
      // Convert to millions (e.g., 1.1m)
      return (viewCount / 1000000).toFixed(1) + "m";
    } else if (viewCount >= 1000) {
      // Convert to thousands (e.g., 1k)
      return (viewCount / 1000).toFixed(0) + "k";
    } else {
      return viewCount.toString();
    }
  }

  // analytics api
  const [dataRows, setDataRows] = useState([]);

  useEffect(() => {
    if (mediaId !== "" && mediaId !== undefined) {
      console.log(mediaId);
      const today = new Date();
      const formattedDate = today.toISOString().split("T")[0];
      const analyticsData = {
        media_ids: [mediaId],
        start_date: getDate(content?.generalContentObj?.createdAt),
        end_date: formattedDate,
      };
      getAnalytics(analyticsData)
        .then((response) => {
          // Handle the response data


          setDataRows(response.data?.rows);
          // setDataMetrics(response.metadata?.column_headers?.metrics);
        })
        .catch((error) => {
          // Handle the error
          // console.error(error);
        });
    }
  }, [content]);



  // -------------------------------------------------------------------
  // Get bank details
  const [bankDetails, setBankDetails] = useState({});

  useEffect(() => {
    fetchUserData().then((data) => setBankDetails(data));
  }, []);

  // Modal for bank details
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [showBankDetails, setShowBankDetails] = useState(false);
  const handleCloseBankDetails = () => setShowBankDetails(false);
  const handleShowBankDetails = () => setShowBankDetails(true);

  const handlePaymentTransfer = () => {
    const requestBody = {
      content: {
        _id: id,
        category: "movie",
        title: movieTitle,
      },
    };

    // Make the POST API call
    axios
      .post(
        `${process.env.REACT_APP_API}/api/user/bank/exclusive-video-charge/${currentUserId}`,
        requestBody
      )
      .then((response) => {
        // If the API call is successful, set isPaymentSuccessful to true
        if (response?.data?.status !== "200") {
          setPaymentStatus("Payment Failed!");
        } else {
          setIsPaymentSuccessful(true);
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  };
  // exclusive content

  // Modal for exclusive
  const [showExcluisive, setShowExclusive] = useState(false);
  const handleCloseExclusive = () => setShowExclusive(false);
  const handleShowExclusive = () => {
    setShowExclusive(true);
    handleClose();
  };
  const videoObj = {
    content: {
      _id: id,
      category: "movie",
      title: movieTitle,
    },
  };
  const handleExclusiveSubmit = async (event) => {
    setLoading(true);
    try {
      const response = await checkUserCard(currentUserId);
      setShowStripeCard(true);
      if (response.data.connected) {
        await videoCharge(currentUserId, videoObj);
        setHasUserPaid(true);
        setCardInfo(true);
        await AuthSession();
        const updatedUserDetails = getSessionData();
        setUserDetails(updatedUserDetails);
      } else {
        setCardInfo(false);
      }
    } catch (error) {
      // console.log(error);
      // Handle the error as needed
    }

    setLoading(false);
  };
  // check if user is paid or not for exclusive content
  const [hasUserPaid, setHasUserPaid] = useState(false);
  useEffect(() => {
    userPaid(currentUserId, { content_id: id, category: "movie" }).then(
      (data) => {
        if (data) {
          setHasUserPaid(true);
        } else {
          setHasUserPaid(false);
        }
        // console.log("user?>>>>", hasUserPaid);
      }
    );
  }, []);

  // hovering modal work

  const [hoveredItem, setHoveredItem] = useState({});
  const modalTimerRef = useRef(null);

  const handleMouseEnter = (item) => {
    // Set the hovered item and start the timer for showing the modal
    setHoveredItem(item);

    modalTimerRef.current = setTimeout(() => {
      setShow1(true);
    }, 3000); // 5 seconds
  };

  const handleMouseLeave = () => {
    // Clear the timer and reset the hovered item
    clearTimeout(modalTimerRef.current);
    // setHoveredItem(null);
  };

  const handleModalClose = () => {
    // Close the modal
    setShow1(false);
  };

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [hoveredItemAd, setHoveredItemAd] = useState({});
  const adModalTimeRef = useRef(null);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleMouseEnterAd = (item) => {
    // Set the hovered item
    setHoveredItemAd(item);

    // Clear the previous timer, if any
    clearTimeout(adModalTimeRef.current);

    if (item?.autoplay === "instant") {
      // Autoplay with a delay of 1 second
      adModalTimeRef.current = setTimeout(() => {
        setShow2(true);
      }, 500); // half a second
    } else if (item?.autoplay === "3 seconds") {
      // Autoplay with a delay of 3 seconds
      adModalTimeRef.current = setTimeout(() => {
        setShow2(true);
      }, 3000);
    } else if (item?.autoplay === "off") {
      // No autoplay
      setShow2(false);
    }
  };

 

const extractText = (html) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  return tempDiv.innerText;
};


  const handleMouseLeaveAd = () => {
    // Clear the timer and reset the hovered item
    clearTimeout(adModalTimeRef.current);
    // setHoveredItem(null);
  };


  

  return (
    <>
    <Helmet>
          <title>{movieTitle}</title>
          <meta property="og:title" content={`${content?.generalContentObj?.media?.title} | AFO Media`} />
          <meta property="og:description" content={extractText(content?.generalContentObj?.media?.description).substr(0, 100)} />
          <meta  property="og:image" content={bannerImg} />
          <meta property="og:url" content={`https://afomedia.com//video-details/app/${id}`} />
          <meta property="og:site_name" content="AFO Media" />
      </Helmet>
      <ToastContainer
        toastStyle={{
          background: "black",
          color: "white",
        }}
      />
      <Modal
        show={show2}
        onHide={handleClose2}
        size="lg"
        centered={true}
        className="trailer-modal"
      // contentClassName="transparent-modal"
      >
        <Modal.Body>
          <AdsModal item={hoveredItemAd} handleClose={handleClose2} />
        </Modal.Body>
      </Modal>
      {/* Modal for bank details */}
      <Modal
        className="deleteModal"
        show={showBankDetails}
        onHide={() => {
          // setShowBankTransferModal(false);
          // setBankDetails(false);
          handleCloseBankDetails();
        }}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Bank Transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            {!isPaymentSuccessful && !paymentStatus && (
              <h6>Bank Transfer - {bankDetails?.bankDetails?.bank_detail}</h6>
            )}
            {isPaymentSuccessful && (
              <h6>
                Confirmation - {bankDetails?.bankDetails?.confirmation_msg}
              </h6>
            )}
            {paymentStatus && <h6>{paymentStatus}</h6>}
          </div>
          <br />
          {!isPaymentSuccessful && !paymentStatus && (
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary delBtn Bank-Type"
                onClick={() => {
                  handlePaymentTransfer();
                }}
              >
                Payment transfer done
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>
      {/* Modal for hovering video */}
      <Modal
        show={show1}
        onHide={handleClose1}
        size="lg"
        centered={true}
        className="trailer-modal"
        contentClassName="transparent-modal"
      >
        <Modal.Body>
          <VideoDescriptionModal
            item={hoveredItem}
            handleClose={handleClose1}
          />
        </Modal.Body>
      </Modal>
      {/* Modal for trailer */}
      <Modal
        show={showTrailerModal}
        onHide={handleCloseTrailerModal}
        className="trailer-modal"
        size="lg"
        centered={true}
      >
        <Modal.Body>
          <div
            style={{
              position: "relative",
              display: "block",
              height: 0,
              padding: 0,
              overflow: "hidden",
              marginBottom: "1em",
              paddingBottom: "56.25%",
            }}
            className="onb-embed-responsive"
          >
             <ReactPlayer
              controls
              width="100%"
              height="100%"
              url={`https://cdn.jwplayer.com/manifests/${trailerId}.m3u8`}
              // url="https://live-hls-web-aje.getaj.net/AJE/05.m3u8s"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                border: 0,
              }}
            />
            
          </div>
        </Modal.Body>
      </Modal>
      {/* Modal for subscription */}
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        centered={true}
        className={`${showStripeCard ? "stripe-Modal" : "special_modal"} `}
      // contentClassName="transparent-modal"
      >
        {contentType === "subscription" ? (
          !showStripeCard ? (
            <Modal.Body>
              <div class="demo">
                <div class="container">
                  <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                    <div class="pricingTable blue">
                      <div class="pricingTable-header">
                        <i class="fa fa-diamond"></i>
                        <div class="price-value">
                          {" "}
                          $20.00 <span class="month">
                            {t("per month")}
                          </span>{" "}
                        </div>
                      </div>
                      <h3 class="heading">{t("Premium")}</h3>
                      <div class="pricing-content">
                        <ul>
                          <li>
                            <b>{t("Unlimited Content")}</b>
                          </li>
                          <li>
                            {t("Contains")} <b>{t("No Ads")}</b>
                          </li>
                          <li>
                            <b>$20</b>/{t("Month")}
                          </li>
                          <li>
                            <b>70GB</b> {t("Monthly Bandwidth")}
                          </li>
                        </ul>
                      </div>
                      <div class="pricingTable-signup">
                        <button
                          className="pricingTable-signup"
                          type="submit"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleSubmit()}
                        >
                          {t("Subscribe")}
                        </button>
                        {/* <Stripe /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          ) : cardInfo ? (
            <>
              <Modal.Header>
                <Modal.Title>{t("Subscription")}</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ marginLeft: "0.5rem" }}>
                <div className="success2FA">
                  <AiOutlineCheckCircle
                    style={{ color: "var(--iq-primary)" }}
                    size={30}
                  />
                  <span>{t("Successfully Subscribed!")}</span>
                </div>
              </Modal.Body>{" "}
              <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                  {t("Close")}
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <Stripe
              message={"Successfully Subscribed!"}
              subscription={"paid"}
              updateData={handleUpdateData}
            />
          )
        ) : !showStripeCard ? (
          <Modal.Body>
            <div class="demo">
              <div class="container">
                <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                  <div class="pricingTable blue">
                    <div class="pricingTable-header">
                      <i class="fa fa-diamond"></i>
                      <div class="price-value"> {price}$</div>
                    </div>
                    <h3 class="heading">{t("Exclusive")}</h3>
                    <div class="pricing-content">
                      <ul>
                        <li>
                          <b>{t("Unlimited Content")}</b>
                        </li>
                        <li>
                          {t("Contains")} <b>{t("No Ads")}</b>
                        </li>
                      </ul>
                    </div>
                    <div class="pricingTable-signup">
                      <button
                        className="pricingTable-signup"
                        type="submit"
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleExclusiveSubmit()}
                        onClick={() => handleShowExclusive()}
                      >
                        {t("Buy")}
                      </button>
                      {/* <Stripe /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        ) : cardInfo ? (
          <>
            <Modal.Header>
              <Modal.Title>{t("Exclusive Content")}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ marginLeft: "0.5rem" }}>
              <div className="success2FA">
                <AiOutlineCheckCircle
                  style={{ color: "var(--iq-primary)" }}
                  size={30}
                />
                <span>{t("Successfully Purchased!")}</span>
              </div>
            </Modal.Body>{" "}
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <Stripe
            message={"Successfully Purchased"}
            subscription={"paid"}
            updateData={handleUpdateData}
            contentType={contentType}
            myObj={videoObj}
            setHasUserPaid={setHasUserPaid}
          />
        )}
      </Modal>
      {/* modal for exclusive content =============================*/}
      <Modal
        className="exclusiveModal"
        show={showExcluisive}
        onHide={handleCloseExclusive}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Payment Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <button
            className="btn btn-primary delBtn Payment-Type"
            onClick={() => handleExclusiveSubmit()}
          >
            Card Payment
          </button>
          <button
            className="btn btn-primary delBtn Payment-Type"
          // onClick={() => { stripModal() }}
          >
            PayPal
          </button>
          <button
            className="btn btn-primary delBtn Payment-Type"
            // onClick={() => { stripModal() }}
            onClick={() => {
              handleShowBankDetails();
              handleCloseExclusive();
            }}
          >
            Bank Transfer
          </button>
        </Modal.Body>
      </Modal>
      {/* ============================================== */}
      {/* Modal for login  */}
      <Modal
        show={showLoginModal}
        onHide={handleCloseLoginModal}
        className="special_modal"
        size="lg"
        centered={true}
      >
        <Modal.Body>
          <LoginSignupModal />
        </Modal.Body>
      </Modal>

      <FsLightbox
        toggler={toggler1}
        sources={[
          <iframe
            src={`https://cdn.jwplayer.com/players/${trailerId}-${playerId}.html`}
            title=" "
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />,
        ]}
      />

      <FsLightbox
        toggler={loginModal}
        sources={[
          width >= 800 ? (
            <section className="sign-in-page">
              <div className="maincontainer">
                {!subscriptionForm ? (
                  <div
                    className={`login-signup-container ${isSignUpActive ? "right-panel-active" : ""
                      }`}
                    id="container"
                  >
                    <div className="login-signup-form-container sign-up-container">
                      <div className="login-signup-form">
                        <h3>{t("Create Account")}</h3>
                        <h4>{t("Personal Details")}</h4>
                        {/* <div
                      className={`alert alert-danger ${
                        showError ? "" : "d-none"
                      }`}
                      role="alert"
                    ></div> */}
                        <div
                          className={`alert alert-danger ${showError ? "" : "d-none"
                            }`}
                          role="alert"
                        >
                          {" "}
                          {error}
                        </div>
                        <input
                          type="text"
                          placeholder="Name"
                          value={formData.name} //setting the value of the form to the props value
                          onChange={
                            (e) =>
                              setFormData({ ...formData, name: e.target.value }) //setting the formData to the value input of the textfield
                          }
                        />
                        <input
                          type="email"
                          placeholder="Email"
                          value={formData.email} //setting the value of the form to the props value
                          onChange={
                            (e) =>
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              }) //setting the formData to the value input of the textfield
                          }
                        />
                        <input
                          type="password"
                          placeholder="Password"
                          value={formData.password} //setting the value of the form to the props value
                          onChange={
                            (e) =>
                              setFormData({
                                ...formData,
                                password: e.target.value,
                              }) //setting the formData to the value input of the textfield
                          }
                        />
                        <button
                          className="button"
                          onClick={() => {
                            handleEmailVerification();
                          }}
                        >
                          {t("Next")}
                        </button>
                      </div>
                    </div>
                    <div className="login-signup-form-container sign-in-container">
                      <form action="#" className="login-signup-form">
                        <h3>{t("sign in").toUpperCase()}</h3>
                        <div
                          className={`alert alert-danger ${showLoginError ? "" : "d-none"
                            }`}
                          role="alert"
                        >
                          {loginError}
                          {error}
                        </div>
                        <div
                          className={`alert alert-danger ${emptyObj ? "" : "d-none"
                            }`}
                          role="alert"
                        >
                          {t("Enter email and password")}
                        </div>
                        <input
                          type="email"
                          placeholder="Email"
                          required
                          name="email"
                          value={loginInput.email || ""}
                          onChange={handleInputChange}
                        />
                        <input
                          type="password"
                          placeholder="Password"
                          required
                          name="password"
                          value={loginInput.password || ""}
                          onChange={handleInputChange}
                        />
                        <Link to="/recover-password" className="f-link">
                          {t("Forgot your password?")}
                        </Link>
                        <button
                          className="button"
                          onClick={loginSubmit}
                        // onClick={() => {
                        //   // history.push("/");

                        // }}
                        >
                          {t("sign in").toUpperCase()}
                        </button>
                      </form>
                    </div>
                    <div className="overlay-container">
                      <div className="overlay">
                        <div className="overlay-panel overlay-left">
                          <h3>{t("Welcome Back!")}</h3>
                          <p>
                            {t(
                              "To keep connected with us please login with your personal info"
                            )}
                          </p>
                          <button
                            className="ghost button"
                            id="signIn"
                            onClick={handleSignInClick}
                          >
                            {t("sign in").toUpperCase()}
                          </button>
                        </div>
                        <div className="overlay-panel overlay-right">
                          <h3>{t("Hello, Friend!")}</h3>
                          <p>
                            {t(
                              "Enter your personal details and start journey with us"
                            )}
                          </p>
                          <button
                            className="ghost button"
                            id="signUp"
                            onClick={handleSignUpClick}
                          >
                            {t("sign up").toUpperCase()}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="form2">
                    <div className="Subscription">
                      <div className="heading-icon">
                        <FcPrevious
                          className="prev-icon"
                          size={30}
                          onClick={() => {
                            setPage(page - 1);
                            setOtp(false);
                            setSubscriptionForm(false);
                          }}
                        />
                        <div className="heading">
                          {t(
                            "Please enter the One-Time Password to verify your account"
                          )}
                        </div>
                      </div>
                      <div className="otpbody">
                        <div className="otp-body">
                          <span style={{ marginBottom: "2rem" }}>
                            {t("A one-Time Password has been sent to")}
                            {formData.email}
                          </span>
                          <OtpInput
                            value={otpValue}
                            onChange={handleOtpChange}
                            numInputs={6}
                            isInputNum
                            renderInput={(inputProps) => (
                              <input {...inputProps} />
                            )}
                            renderSeparator={<span>-</span>}
                            inputStyle={{
                              width: "3rem",
                              height: "3rem",
                              margin: "0 0.5rem",

                              borderRadius: 4,

                              outline: "none",
                              textAlign: "center",
                            }}
                          />
                        </div>
                        {wrongEmailCode && (
                          <span style={{ color: "red" }}>
                            {t(
                              "Entered wrong code, please enter the code sent on"
                            )}
                            {formData.email}{" "}
                          </span>
                        )}
                        {userExistError !== "" && (
                          <span style={{ color: "red" }}>{userExistError}</span>
                        )}
                        <button
                          className="button"
                          // onClick={handleSignUp}

                          onClick={handleEmailCodeVerification}
                        >
                          {t("Validate")}
                        </button>

                        <div className="otp-body">
                          <h4>
                            <Link
                              onClick={() => {
                                sendEmailVerification(obj);
                                setOtpValue("");
                              }}
                            >
                              {t("Resend One Time Password")}
                            </Link>
                          </h4>
                          <span style={{ marginBottom: "2rem" }}>
                            <Link
                              onClick={() => {
                                setSubscriptionForm(false);
                                setOtp(false);
                              }}
                            >
                              {t("Entered a Wrong Email?")}
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
          ) : (
            <Loginsignup nextPath="" />
          ),
        ]}
      />

      <FsLightbox
        toggler={pricingModal}
        sources={[
          <section>
            {!checkout ? (
              <div className="form2">
                <div className="Subscription">
                  <div className="heading-icon">
                    <FcExpand
                      className="prev-icon"
                      size={30}
                      onClick={() => setPricingModal(!pricingModal)}
                    />
                    <div className="heading mr-5">
                      {t("Add your premium account")}
                    </div>
                  </div>

                  <div className="subscriptionCards">
                    <div
                      className={`${activeCard ? "activeCard" : "inActiveCard"
                        }`}
                    >
                      <div className="subCard">
                        <div className="subCardBody">
                          <h4>{t("premium").toUpperCase()}</h4>
                          <div className="cardText">
                            <ul>
                              <li>{t("Unlimited Content")}</li>
                              <li>
                                {t("Contains")} {t("No Ads")}
                              </li>
                              <li>20$/{t("Month")}</li>
                            </ul>
                          </div>
                        </div>
                        <div className="btn-price">
                          <div style={{ marginLeft: "1.5rem" }}>
                            <h6>20$ /{t("Month")}</h6>
                            <span>{t("Unlimited Access")}</span>
                          </div>

                          <button
                            className={`subBtn ${activeCard ? "subBtn-active" : ""
                              }`}
                            value="paid"
                            onClick={() => setCheckout(true)}
                          >
                            {t("Buy Premium")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="checkout_wrapper_main">
                <div className="checkout_wrapper">
                  <div className="product_info">
                    <img src={logo} alt="logo" />
                    <div className="content">
                      {/* <h3>Subscribe to watch</h3> */}
                    </div>
                  </div>
                  <div className="checkout_form">
                    <p>{t("Payment Section")}</p>
                    <div className="details">
                      <div className="section">
                        <input type="text" placeholder="Card Number" />
                      </div>
                      <div className="section">
                        <input type="text" placeholder="Cardholder Name" />
                      </div>
                      <div className="section last_section">
                        <div className="item">
                          <input type="text" placeholder="Expiry Date" />
                        </div>
                        <div className="item">
                          <input type="text" placeholder="CVV" />
                        </div>
                      </div>

                      <div className="btn">
                        <a href="#">{t("Pay")}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>,
        ]}
      />
      <section className="relative">
        {loader && (
          <div className="loader" style={{ minHeight: "100vh" }}>
            <div className="outer"></div>
            <div className="middle"></div>
            <div className="inner"></div>
          </div>
        )}
        {!loader && (
          <>
            {/* Condition 1: User not logged in */}
            {/* {!currentUserId && (
              <div>
                <button>Login</button>
              </div>
            )} */}
            {/* if  content type is free => show the video */}

            {availability === "upcoming" ? (
              <section
                className="container-fluid"
                style={{ paddingTop: "6rem" }}
              >
                <VideoPoster
                  thumbnail={bannerImg}
                  title={movieTitle}
                  crewNames={crewNames}
                  crewRoles={crewRoles}
                  createdAt={releaseDate}
                  jwTags={jwTags}
                  genre={genre}
                  trailer={trailerId}
                  availability={availability}
                  monetizationCheck={monetizationCheck}
                />
              </section>
            ) : contentType === "free" ? (
              <div
                className="container-fluid"
                style={{
                  paddingBottom: width < 770 ? "0px" : "2rem",
                  paddingTop: width < 770 ? "5rem" : "8rem",
                  // display: "flex",
                  // justifyContent: "center",
                  width: width < 770 ? "100%" : "73%",
                }}
              >
                {isPrerender()  ? null : <ReactJWPlayer
                  playerId={playerId}
                  playerScript={`https://content.jwplatform.com/libraries/${playerId}.js`}
                  playlist={`https://cdn.jwplayer.com/v2/media/${mediaId}`}
                  controls={false}
                  isAutoPlay={true}
                // onPlay={handlePlay}
                // licenseKey="cGgETDr1vxOKSYWdWFdE2OCv2XtlOIPWLBvS+wBkAiPBxF9kqH2ftZqe3zo="
                // file={`https://cdn.jwplayer.com/players/${mediaId}-${playerId}.html`}
                // onReady={onReady}
                />}
                
              </div>
              
            ) : contentType === "subscription" ? (
              contentType === "subscription" &&
                currentUser?.paid === true && expirationDate > Date.now() &&
                currentUserId !== "" ? (
                <div
                  className="container-fluid"
                  style={{
                    paddingBottom: width < 770 ? "0px" : "2rem",
                    paddingTop: width < 770 ? "5rem" : "8rem",
                    width: width < 770 ? "100%" : "73%",
                  }}
                >
                 
                  <ReactJWPlayer
                    playerId={playerId}
                    playerScript={`https://content.jwplatform.com/libraries/${playerId}.js`}
                    playlist={`https://cdn.jwplayer.com/v2/media/${mediaId}`}
                    controls={false}
                    isAutoPlay={true}
                  // onPlay={handlePlay}
                  // licenseKey="cGgETDr1vxOKSYWdWFdE2OCv2XtlOIPWLBvS+wBkAiPBxF9kqH2ftZqe3zo="
                  />
                </div>
              ) : (
                <div
                  style={
                    {
                      // paddingBottom: "1rem",
                      // paddingTop: width < 770 ? "5rem" : "8rem",
                    }
                  }
                >
                  {/* lock new */}
                  <section
                    className="container-fluid"
                    style={{
                      paddingTop: "6rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingBottom: width < 770 ? "0px" : "2rem",
                    }}
                  >
                    <div className="lock-banner animated">
                      <div
                        className="poster"
                        style={{
                          backgroundImage: `url(${bannerImg})`,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="overlay_poster"></div>
                        {/* if logged in but not free content */}
                        {currentUserId &&
                          contentType === "subscription" &&
                          (currentUser?.paid === false || (currentUser?.paid === true && expirationDate < Date.now()) ) && (
                            // subscribe to unlock
                            <div
                              to="/"
                              className="video-open playbtn gap-5"
                              onClick={handleShow}
                              style={{
                                // position: "absolute",
                                // top: "40%",
                                // left: "25%",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                                zIndex: "99",
                              }}
                            >
                              <svg
                                fill="#7accf5"
                                width="60px"
                                height="60px"
                                viewBox="0 0 32 32"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                stroke="#7accf5"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0" />
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />

                                <g id="SVGRepo_iconCarrier">
                                  <path d="M25 12h-1v-3.816c0-4.589-3.32-8.184-8.037-8.184-4.736 0-7.963 3.671-7.963 8.184v3.816h-1c-2.206 0-4 1.794-4 4v12c0 2.206 1.794 4 4 4h18c2.206 0 4-1.794 4-4v-12c0-2.206-1.794-4-4-4zM10 8.184c0-3.409 2.33-6.184 5.963-6.184 3.596 0 6.037 2.716 6.037 6.184v3.816h-12v-3.816zM27 28c0 1.102-0.898 2-2 2h-18c-1.103 0-2-0.898-2-2v-12c0-1.102 0.897-2 2-2h18c1.102 0 2 0.898 2 2v12zM16 18c-1.104 0-2 0.895-2 2 0 0.738 0.405 1.376 1 1.723v3.277c0 0.552 0.448 1 1 1s1-0.448 1-1v-3.277c0.595-0.346 1-0.985 1-1.723 0-1.105-0.895-2-2-2z" />{" "}
                                </g>
                              </svg>
                              <span
                                className="unlock-text"
                                style={{ cursor: "pointer" }}
                              >
                                {t("Subscribe to unlock")}
                              </span>
                            </div>
                          )}
                        {!currentUserId && contentType === "subscription" && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              zIndex: "99",
                            }}
                          >
                            <span className="unlock-text">
                              {t("Login first to watch the video")}
                            </span>
                            <div
                              onClick={handleShowLoginModal}
                              className="btn btn-hover iq-button mt-2"
                              style={{
                                width: "fit-content",
                                height: "fit-content",
                              }}
                            >
                              <i
                                className="fa fa-lock mr-2"
                                aria-hidden="true"
                              ></i>
                              {t("Login to watch")}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              )
            ) : contentType === "exclusive" &&
              hasUserPaid === true &&
              currentUserId !== "" ? (
              <div
                className="container-fluid"
                style={{
                  paddingBottom: width < 770 ? "0px" : "2rem",
                  paddingTop: width < 770 ? "5rem" : "8rem",

                  width: width < 770 ? "100%" : "73%",
                }}
              >
                   
                <ReactJWPlayer
                  playerId={playerId}
                  playerScript={`https://content.jwplatform.com/libraries/${playerId}.js`}
                  playlist={`https://cdn.jwplayer.com/v2/media/${mediaId}`}
                  controls={false}
                  isAutoPlay={true}
                // onPlay={handlePlay}
                // licenseKey="cGgETDr1vxOKSYWdWFdE2OCv2XtlOIPWLBvS+wBkAiPBxF9kqH2ftZqe3zo="
                /> 
              </div>
            ) : (
              <div
                style={
                  {
                    // paddingBottom: "1rem",
                    // paddingTop: width < 770 ? "5rem" : "8rem",
                  }
                }
              >
                {/* lock new */}
                <section
                  className="container-fluid"
                  style={{
                    paddingTop: "6rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: width < 770 ? "0px" : "2rem",
                  }}
                >
                  <div className="lock-banner animated">
                    <div
                      className="poster"
                      style={{
                        backgroundImage: `url(${bannerImg})`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="overlay_poster"></div>
                      {/* if logged in but not free content */}
                      {currentUserId &&
                        contentType === "exclusive" &&
                        hasUserPaid === false && (
                          // subscribe to unlock
                          <div
                            to="/"
                            className="lock-banner-content gap-5"
                            style={{
                              // position: "absolute",
                              // top: "40%",
                              // left: "25%",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                              zIndex: "99",
                            }}
                          >
                            <svg
                              fill="#7accf5"
                              viewBox="0 0 32 32"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#7accf5"
                              className="unlock-svg"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0" />
                              <g
                                id="SVGRepo_tracerCarrier"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />

                              <g id="SVGRepo_iconCarrier">
                                <path d="M25 12h-1v-3.816c0-4.589-3.32-8.184-8.037-8.184-4.736 0-7.963 3.671-7.963 8.184v3.816h-1c-2.206 0-4 1.794-4 4v12c0 2.206 1.794 4 4 4h18c2.206 0 4-1.794 4-4v-12c0-2.206-1.794-4-4-4zM10 8.184c0-3.409 2.33-6.184 5.963-6.184 3.596 0 6.037 2.716 6.037 6.184v3.816h-12v-3.816zM27 28c0 1.102-0.898 2-2 2h-18c-1.103 0-2-0.898-2-2v-12c0-1.102 0.897-2 2-2h18c1.102 0 2 0.898 2 2v12zM16 18c-1.104 0-2 0.895-2 2 0 0.738 0.405 1.376 1 1.723v3.277c0 0.552 0.448 1 1 1s1-0.448 1-1v-3.277c0.595-0.346 1-0.985 1-1.723 0-1.105-0.895-2-2-2z" />{" "}
                              </g>
                            </svg>
                            <span className=" unlock-text">
                              {t("Pay to unlock Exclusive Content")}
                            </span>
                            <div
                              className="btn btn-hover iq-button"
                              onClick={handleShow}
                            >
                              {t("Get exclusive content")}
                            </div>
                          </div>
                        )}
                      {!currentUserId && contentType === "exclusive" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: "99",
                          }}
                        >
                          <span className=" login-unlock-text">
                            {t("Login first to watch the video")}
                          </span>
                          <div
                            onClick={handleShowLoginModal}
                            className="btn btn-hover iq-button mt-2"
                            style={{
                              width: "fit-content",
                              height: "fit-content",
                            }}
                          >
                            <i
                              className="fa fa-lock mr-2"
                              aria-hidden="true"
                            ></i>
                            {t("Login to watch")}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            )}
            <div className="main-content movi">
              <section className="movie-detail container-fluid">
                <Row>
                  <Col lg="12">
                    <div className="trending-info g-border">
                      <div className="d-flex justify-content-between movie-info-container">
                        <div>
                          <h1 className="trending-text big-title text-uppercase mt-0 mr-2">
                            {movieTitle}
                          </h1>
                          <div>
                            <ul className="p-0 list-inline d-flex movie-content flex-wrap">
                              {genre?.map((genreObj, index) => (
                                <li className="text-white" key={index}>
                                  {genreObj?.name}
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="d-flex align-items-center text-white text-detail flex-wrap">
                            {/* {mediaId !== ""} */}
                            <span
                              className=""
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                gap: "0.5rem",
                              }}
                            >
                              <i
                                class="ri-eye-line"
                                style={{
                                  color: "#21a6e0",
                                  fontSize: "17px",
                                }}
                              ></i>

                              {dataRows && dataRows.length > 0 ? (
                                <span>
                                  {formatViewCount(dataRows[0][3])} {t("Views")}
                                </span>
                              ) : (
                                <span>0 {t("Views")}</span>
                              )}
                            </span>
                            <span className="trending-year">
                              {convertSecondsToHoursMinutes(duration)}
                            </span>
                            <span className="trending-year">
                              {dateToWords(releaseDate)}
                            </span>
                          </div>
                          <ul className="list-inline p-0 mt-4 share-icons music-play-lists">
                            <li className="share">
                              <span>
                                <i
                                  className={`ri-add-line ${watchListItems.includes(id)
                                    ? "red-heart"
                                    : ""
                                    }`}
                                  onClick={(event) =>
                                    handleWatchList(event, id, category)
                                  }
                                  title={
                                    watchListItems.includes(id)
                                      ? t("Remove From WatchList")
                                      : t("Add To WatchList")
                                  }
                                ></i>
                              </span>
                              {/* <div className="favorite-box">
                                <div className="">Add to wishlist</div>
                              </div> */}
                            </li>

                            <li className="share">
                              <span>
                                <i
                                  className={`ri-heart-fill ${favoriteItems.includes(id)
                                    ? "red-heart"
                                    : ""
                                    }`}
                                  onClick={(event) =>
                                    handleFavClick(event, id, category)
                                  }
                                  title={
                                    favoriteItems.includes(id)
                                      ? t("Remove From Favourite")
                                      : t("Add To Favourite")
                                  }
                                ></i>
                              </span>
                              {/* <div className="favorite-box">
                                <div className="">Add to favorites</div>
                              </div> */}
                            </li>
                            <li className="share">
                              <span>
                                <i className="ri-share-fill"></i>
                              </span>
                              <div className="share-box">
                                <div className="d-flex align-items-center">
                                  <a
                                    href={VideoFbShareUrl(
                                      id,
                                      mediaCategory,
                                      movieTitle,
                                      mediaDesc
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="share-ico"
                                    tabIndex="0"
                                  >
                                    <i className="ri-facebook-fill"></i>
                                  </a>
                                  <a
                                    href={VideoTwitterShareUrl(
                                      id,
                                      mediaCategory,
                                      movieTitle,
                                      mediaDesc
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="share-ico"
                                    tabIndex="0"
                                  >
                                    <i className="ri-twitter-fill"></i>
                                  </a>
                                  <a
                                    href={VideoWhatsappShareUrl(
                                      id,
                                      mediaCategory,
                                      movieTitle,
                                      mediaDesc,
                                      movieShortLink
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="share-ico iq-copy-link"
                                    tabIndex="0"
                                  >
                                    <i className="ri-whatsapp-fill"></i>
                                  </a>
                                    <CopyLinkButton link={movieShortLink}/>
                                </div>
                              </div>
                            </li>

                            <li className="share">
                              <span>
                                <i
                                  className={`ri-thumb-up-fill ${isLiked ? "blue-thumb-up" : ""
                                    }`}
                                  onClick={handleLike}
                                  title={isLiked ? t("UnLike") : t("Like")}
                                ></i>
                                <span
                                  className="count-box"
                                  style={{ color: "white" }}
                                >
                                  {likeCount}
                                </span>
                              </span>
                              {/* <div className="favorite-box">
                                <div className="">Like </div>
                              </div> */}
                            </li>
                          </ul>
                          {/* <div className="d-flex movie-tags mb-4">
                            <div>
                              <i className="fa fa-tags mr-1"></i>
                              Tags:
                            </div>
                            <div className="d-flex movie-tags-tag flex-wrap">
                              {jwTags?.map((jwTagsObj, index) => (
                                <span
                                  className="text-white"
                                  key={jwTagsObj._id}
                                >
                                  {jwTagsObj}
                                  {index < jwTags.length - 1 && ","}
                                </span>
                              ))}
                               
                            </div>
                          </div> */}
                          {/* {jwTags && jwTags?.length > 0 && (
                            <div className="movie-tags  ">
                              <div className=" text-white movie-desc-navigator__desc-text">
                                <i
                                  className="fa fa-tags mr-1"
                                  style={{
                                    color: "#21a6e0",
                                    fontSize: "17px",
                                  }}
                                ></i>
                                <span
                                  style={{
                                    color: "#21a6e0",
                                    marginRight: "1rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("tags").toUpperCase()}:
                                </span>
                                {jwTags?.map((tag, index) => (
                                  <React.Fragment key={index}>
                                    {index > 0 && ", "}
                                    <span className="text-white">{tag}</span>
                                  </React.Fragment>
                                ))}
                              </div>
                            </div>
                          )} */}
                        </div>
                        {mediaCategory !== "video" &&
                          trailerId &&
                          availability !== "upcoming" ? (
                          <div>
                            <div
                              className="trailer-container"
                              onClick={handleShowTrailerModal}
                            // onClick={handleShowLoginModal}
                            >
                              <img
                                src={bannerImg}
                                alt="Background"
                                className="trailer-image"
                              />
                              <div className="trailer-overlay"></div>
                              <div className="trailer-text">Watch trailer</div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                </Row>
              </section>
              <section>
                <div className="movie-desc-navigator">
                  <button
                    className={` ${activeBtn === "description"
                      ? "movie-desc-navigator__active-btn movie-desc-navigator-btn "
                      : "movie-desc-navigator-btn"
                      }`}
                    onClick={() => handleClick("description")}
                  >
                    {t("Description")}
                  </button>
                  <button
                    className={` ${activeBtn === "review"
                      ? "movie-desc-navigator__active-btn movie-desc-navigator-btn"
                      : "movie-desc-navigator-btn"
                      }`}
                    onClick={() => handleClick("review")}
                  >
                    {t("Leave a Comment")}
                  </button>
                </div>
              </section>
              <section>
                {activeBtn === "description" && (
                  <section className="container-fluid">
                    <Row className=" fs-5 mt-5">
                      <Col lg="12" md="12" className="overflow-hidden">
                        <span
                          className="movie-desc-navigator__desc-text "
                          dangerouslySetInnerHTML={{
                            __html: mediaDesc,
                          }}
                        ></span>
                        {crewNames?.length > 0 && (
                          <>
                            <h4 className="mt-4 mb-4 movie-desc-crew-heading">
                              {t("Crew mem")}
                            </h4>
                            <Row>
                              <Col xs="8" md="6">
                                <table style={{ borderCollapse: "collapse" }}>
                                  {crewNames?.map((name, index) => (
                                    <tr key={index}>
                                      <td
                                        style={{
                                          padding: "10px 20px 0 0",
                                          color: "white",
                                        }}
                                      >
                                        {name}
                                      </td>
                                      <td
                                        style={{
                                          padding: "10px 0 0 20px",
                                          marginLeft: "5px",
                                          color: "lightgray",
                                        }}
                                      >
                                        {crewRoles[index]}
                                      </td>
                                    </tr>
                                  ))}
                                </table>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>
                  </section>
                )}
                {activeBtn === "review" && (
                  <section className="mt-5 mb-5 container-fluid">
                    <h4 className="mb-2 comments-heading">{t("Comments")}:</h4>

                    <Comments
                      generalID={generalContentId}
                      mediaCategory={mediaCategory}
                      commentFlag={commentFlag}
                      setCommentFlag={setCommentFlag}
                    />
                    <AddComment
                      generalID={generalContentId}
                      mediaCategory={mediaCategory}
                      setCommentFlag={setCommentFlag}
                    />
                  </section>
                )}

                <div className="gradient-line"></div>
              </section>
              {/* {movies?.map((moviesData, index) => (
                <section id="iq-favorites">
                  <Container fluid>
                    <Row>
                      <Col sm="12" className="overflow-hidden">
                        <div id="upcoming-contens">
                          <div
                            id={`prev2${index}`}
                            className="swiper-button swiper-button-prev"
                          >
                            <i className="fa fa-chevron-left"></i>
                          </div>
                          <div
                            id={`next2${index}`}
                            className="swiper-button swiper-button-next"
                          >
                            <i className="fa fa-chevron-right"></i>
                          </div>
                          <Swiper
                            navigation={{
                              nextEl: `#prev2${index}`,
                              prevEl: `#next2${index}`,
                            }}
                            breakpoints={{
                              320: { slidesPerView: 1 },
                              550: { slidesPerView: 2 },
                              991: { slidesPerView: 3 },
                              1400: { slidesPerView: 4 },
                              1600: { slidesPerView: 5 },
                            }}
                            loop={true}
                            slidesPerView={4}
                            spaceBetween={20}
                            as="ul"
                            className="favorites-slider list-inline row p-0 m-0 iq-rtl-direction"
                          >
                            {moviesData?.upcomingContent.map((data) => (
                              <SwiperSlide as="li" key={data._id}>
                                <div className="block-images position-relative slider-img-main">
                                  <div className="img-box">
                                    <img
                                      src={data?.thumbnail?.static_thumbnail_url}
                                      className="img-fluid slider-img"
                                      alt={data?.media?.title}
                                    />
                                  </div>
                                  <div className="block-description">
                                    <h6 className="iq-title title-desc">
                                      <Link to="/show-details">{data?.media?.title}</Link>
                                    </h6>
                                    <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                                      <div className="badge badge-secondary p-1 mr-2">
                                        {data?.genre_type}
                                      </div>
                                    </div>
                                    <div className="block-social-info-button">
                                      <li className="share">
                                        <div className="hover-buttons">
                                          <a
                                            href={`/videos/${data?.slug}`}
                                            className="btn btn-hover iq-button"
                                          >
                                            <i className="fa fa-play mr-2" aria-hidden="true"></i>
                                            Play Now
                                          </a>
                                        </div>
                                      </li>
                                    </div>
                                  </div>
                                </div >
                              </SwiperSlide >
                            ))}
                          </Swiper >
                        </div >
                      </Col >
                    </Row >
                  </Container >
                </section >
              ))} */}
              {moreLikeThisCopy?.length > 0 && (
                <section id="iq-favorites">
                  <Container fluid>
                    <Row>
                      <Col sm="12" className="overflow-hidden">
                        <div className="d-flex align-items-center justify-content-between">
                          <h4 className="main-title">{t("More Like This")}</h4>
                          {/* <Link className="iq-view-all" to="/">
                    View All
                  </Link> */}
                        </div>
                        <div id="favorites-contens">
                          <div
                            id="prev2"
                            className="swiper-button swiper-button-prev"
                          >
                            <i className="fa fa-chevron-left"></i>
                          </div>
                          <div
                            id="next2"
                            className="swiper-button swiper-button-next"
                          >
                            <i className="fa fa-chevron-right"></i>
                          </div>
                          <Swiper
                            navigation={{
                              prevEl: "#prev2",
                              nextEl: "#next2",
                            }}
                            breakpoints={{
                              320: { slidesPerView: 1 },
                              550: { slidesPerView: 2 },
                              991: { slidesPerView: 3 },
                              1400: { slidesPerView: 4 },
                              1600: { slidesPerView: 5 },
                            }}
                            loop={false}
                            slidesPerView={4}
                            spaceBetween={20}
                            as="ul"
                            className="favorites-slider list-inline row p-0 m-0 iq-rtl-direction"
                          >
                            {moreLikeThisCopy?.map((data) => (
                              <SwiperSlide as="li">
                                <a
                                  href={
                                    data?.category !== "ads"
                                      ? `/videos/${data?.slug}`
                                      : null
                                  }
                                  onClick={(event) =>
                                    handleAdClicked(event, data)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <div
                                    className="block-images position-relative slider-img-main"
                                    onMouseEnter={() => {
                                      if (data?.category !== "ads") {
                                        handleMouseEnter(data);
                                      } else {
                                        handleMouseEnterAd(data);
                                      }
                                    }}
                                    onMouseLeave={() => {
                                      if (data?.category !== "ads") {
                                        handleMouseLeave();
                                      } else {
                                        handleMouseLeaveAd();
                                      }
                                    }}
                                  // style={{display: "flex", justifyContent: "center", alignItems:"center"}}
                                  >
                                    <div className="img-box">
                                      {/* Only render the video if the media category is "movie" */}
                                      {data?.category === "movie" ? (
                                        <img
                                          src={
                                            data?.thumbnail
                                              ?.banner_thumbnail_url
                                          }
                                          className="img-fluid slider-img"
                                          alt=""
                                        />
                                      ) : data?.media_id !== "" ? (
                                        <ReactPlayer
                                          className="img-fluid slider-img"
                                          url={`https://cdn.jwplayer.com/manifests/${data?.media_id}.m3u8`}
                                          width="100%"
                                          height="100%"
                                          pip={false}
                                          controls={false}
                                          playing={true}
                                          muted={true}
                                          loop={true}
                                        />
                                      ) : (
                                        <img
                                          src={data?.banner_url}
                                          className="img-fluid slider-img"
                                          alt=""
                                        />
                                      )}
                                    </div>
                                    {/* showing on the top */}
                                    {/* <div className="card-slider-badge">
                                      <div className="badge badge-secondary p-1 mr-2">
                                        {data?.category === "movie" ||
                                          data?.category === "video"
                                          ? convertSecondsToHoursMinutes(
                                            data?.media?.duration.toFixed(2)
                                          )
                                          : data?.category === "ads"
                                            ? null
                                            : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                              ? "Episode"
                                              : "Episodes"
                                            }`}
                                      </div>
                                    </div> */}
                                    <div className="block-description ">
                                      {/* <div className="card-slider-content">
                                        {data?.category === "movie" ||
                                          data?.category === "video" ? (
                                          <h6 className="iq-title title-desc">
                                          
                                             


                                             {currentSelectedLanguage !== "fr" ? data?.media?.translated_content?.find(e =>  e.language_code === "en")?.title_translated : data?.media?.title } ;
   

                                          </h6>
                                        ) : (
                                          <h6 className="iq-title title-desc">
                                           

                                            {currentSelectedLanguage !== "fr" ? data?.translated_content?.find(e =>  e.language_code === "en")?.title_translated : data?.title } ;
                                          </h6>
                                        )}
                                      </div> */}
                                      {/* <div className="card-slider-desc">
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: limitTextToWords(
                                              data?.category === "movies"
                                                ?  (currentSelectedLanguage !== "fr" ? data?.media?.translated_content?.find(e =>  e.language_code === "en")?.description_translated : data?.media?.description )
                                                : (currentSelectedLanguage !== "fr" ? data?.translated_content?.find(e =>  e.language_code === "en")?.description_translated : data?.description ) ,
                                              12
                                            ),
                                          }}
                                        ></span>
                                      </div> */}
                                      {data?.category !== "ads" && (
                                        <div className="block-social-info-button">
                                          <li className="share">
                                            <div className="hover-buttons">
                                              {data?.category === "movie" ||
                                                data?.category === "video" ? (
                                                <Link
                                                  to={`/videos/${data?.slug}`}
                                                  className="btn btn-hover iq-button"
                                                >
                                                  <i
                                                    className="fa fa-play mr-2"
                                                    aria-hidden="true"
                                                  ></i>
                                                  {t("Play now")}
                                                </Link>
                                              ) : (
                                                <Link
                                                  to={`/show-info/${data?._id}`}
                                                  className="btn btn-hover iq-button"
                                                >
                                                  <i
                                                    className="fa fa-play mr-2"
                                                    aria-hidden="true"
                                                  ></i>
                                                  {t("Play now")}
                                                </Link>
                                              )}
                                            </div>
                                          </li>
                                        </div>
                                      )}
                                    </div>
                                    {data?.category !== "ads" && (
                                      <div className="block-social-info">
                                        <ul className="list-inline p-0 m-0 music-play-lists">
                                          <li className="share">
                                            <span>
                                              <i className="ri-share-fill"></i>
                                            </span>
                                            <div className="share-box">
                                              <div className="d-flex align-items-center">
                                                <a
                                                  href={generateLinkedinShareUrl(
                                                    data,
                                                    data?.category
                                                  )}
                                                  onClick={handleAnchorClick}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                >
                                                  <i className="ri-linkedin-fill"></i>
                                                </a>
                                                <a
                                                  href={generateInstagramShareUrl(
                                                    data,
                                                    
                                                    data?.category
                                                  )}
                                                  onClick={handleAnchorClick}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                >
                                                  <i className="ri-instagram-fill"></i>
                                                </a>
                                                <a
                                                  href={generateSnapchatShareUrl(
                                                    data,
                                                    
                                                    data?.category
                                                  )}
                                                  onClick={handleAnchorClick}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                >
                                                  <i className="ri-snapchat-fill"></i>
                                                </a>
                                                <a
                                                  href={generateTikTokShareUrl(
                                                    data,
                                                    
                                                    data?.category
                                                  )}
                                                  onClick={handleAnchorClick}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                >
                                                  <i className="ri-tiktok-fill"></i>
                                                </a>
                                                <a
                                                  href={generateFacebookShareUrl(
                                                    data,
                                                    
                                                    data?.category
                                                  )}
                                                  onClick={handleAnchorClick}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                >
                                                  <i className="ri-facebook-fill"></i>
                                                </a>
                                                <a
                                                  href={generateTwitterShareUrl(
                                                    data,
                                                    
                                                    data?.category
                                                  )}
                                                  onClick={handleAnchorClick}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                >
                                                  <i className="ri-twitter-fill"></i>
                                                </a>
                                                <a
                                                  href={generateWhatsappShareUrl(
                                                    data,
                                                    
                                                    data?.category
                                                  )}
                                                  onClick={handleAnchorClick}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico iq-copy-link"
                                                  tabIndex="0"
                                                >
                                                  <i className="ri-whatsapp-fill"></i>
                                                </a>
                                                <a
                                                  href={generatePinterestShareUrl(
                                                    data,
                                                    
                                                    data?.category
                                                  )}
                                                  onClick={handleAnchorClick}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                >
                                                  <i className="ri-pinterest-fill"></i>
                                                </a>
                                                <a
                                                  href={generateTelegramShareUrl(
                                                    data,
                                                    
                                                    data?.category
                                                  )}
                                                  onClick={handleAnchorClick}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                >
                                                  <i className="ri-telegram-fill"></i>
                                                </a>
                                                <a
                                                  href={generateRedditShareUrl(
                                                    data,
                                                    
                                                    data?.category
                                                  )}
                                                  onClick={handleAnchorClick}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                >
                                                  <i className="ri-reddit-fill"></i>
                                                </a>
                                                <a
                                                  href={generateQuoraShareUrl(
                                                    data,
                                                    
                                                    data?.category
                                                  )}
                                                  onClick={handleAnchorClick}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="share-ico"
                                                  tabIndex="0"
                                                >
                                                  <i className="ri-quora-fill"></i>
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <span>
                                              <i
                                                className={`ri-heart-fill ${favoriteItems.includes(
                                                  data?._id
                                                )
                                                  ? "red-heart"
                                                  : ""
                                                  }`}
                                                onClick={(event) =>
                                                  handleFavClick(
                                                    event,
                                                    data?._id,
                                                    data?.category
                                                  )
                                                }
                                                title={
                                                  favoriteItems.includes(
                                                    data?._id
                                                  )
                                                    ? t("Remove From Favourite")
                                                    : t("Add To Favourite")
                                                }
                                              ></i>
                                            </span>
                                            {/* <span className="count-box">19+</span> */}
                                          </li>
                                          <li>
                                            <span>
                                              <i
                                                className={`ri-add-line ${watchListItems.includes(
                                                  data?._id
                                                )
                                                  ? "red-heart"
                                                  : ""
                                                  }`}
                                                onClick={(event) =>
                                                  handleWatchList(
                                                    event,
                                                    data?._id,
                                                    data?.category
                                                  )
                                                }
                                                title={
                                                  watchListItems.includes(
                                                    data?._id
                                                  )
                                                    ? t("Remove From WatchList")
                                                    : t("Add To WatchList")
                                                }
                                              ></i>
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                  </div>

                                  <div className="d-flex mt-4 justify-content-between align-items-center">
                                    <div className="w-90">
                                        <h6 className="" style={{fontSize : '18px', marginBottom : '0px' , padding : "5px"}}>
                                        {data?.category === "movie" ||
                                          data?.category === "video" ? (currentSelectedLanguage === 'fr' ? data?.media?.title:  data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated ) : data.media.title
                                        }
                                          </h6>
                                    </div>
                                    <div className="w-10">
                                    <div className="badge badge-secondary p-1" style={{fontSize : '70%'}}> 
                                    {data?.category === "movie" ||
                                          data?.category === "video"
                                          ? convertSecondsToHoursMinutes(data?.media?.duration.toFixed(2))
                                          : data?.category === "ads"
                                            ? null
                                            : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                              ? "Episode"
                                              : "Episodes"
                                            }`}
                                      </div>
                                    </div>
                                  </div>
                                </a>

                                
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
              {videosGenre.length > 0 &&
                videosGenre.map((data, index) => {
                  if (data?.filteredContent?.length > 0) {
                    return (
                      <section id="iq-upcoming-movie">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-sm-12 overflow-hidden">
                              <div className="d-flex align-items-center justify-content-between">
                                <h4 className="main-title">{t(data.genre)}</h4>
                                {/* <Link className="iq-view-all" to="/">
                          View All
                        </Link> */}
                              </div>

                              <div id="upcoming-contens">
                                <div
                                  id={`prev2${index}`}
                                  className="swiper-button swiper-button-prev"
                                >
                                  <i className="fa fa-chevron-left"></i>
                                </div>
                                <div
                                  id={`next2${index}`}
                                  className="swiper-button swiper-button-next"
                                >
                                  <i className="fa fa-chevron-right"></i>
                                </div>
                                <Swiper
                                  slidesPerView={4}
                                  spaceBetween={20}
                                  navigation={{
                                    nextEl: `#prev2${index}`,
                                    prevEl: `#next2${index}`,
                                  }}
                                  loop={false}
                                  breakpoints={{
                                    320: { slidesPerView: 1 },
                                    550: { slidesPerView: 2 },
                                    991: { slidesPerView: 3 },
                                    1400: { slidesPerView: 4 },
                                    1600: { slidesPerView: 5 },
                                  }}
                                  className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                                >
                                  {data?.filteredContent?.map((Obj, index) => (
                                    <SwiperSlide as="li">
                                      <a
                                        href={
                                          Obj?.category === "movie" ||
                                            Obj?.category === "video"
                                            ? `/videos/${Obj?.slug}`
                                            : `/show-info/${Obj?._id}`
                                        }
                                      >
                                        <div
                                          className="block-images position-relative slider-img-main"
                                          onMouseEnter={() =>
                                            handleMouseEnter(Obj)
                                          }
                                          onMouseLeave={handleMouseLeave}
                                        // style={{display: "flex", justifyContent: "center", alignItems:"center"}}
                                        >
                                          <div className="img-box">
                                            <img
                                              src={
                                                Obj?.thumbnail
                                                  ?.banner_thumbnail_url
                                              }
                                              className="img-fluid slider-img"
                                              alt=""
                                            />
                                          </div>
                                          {/* showing on the top */}
                                          {/* <div className="card-slider-badge">
                                            <div className="badge badge-secondary p-1 mr-2">
                                              {Obj?.category === "movie" ||
                                                Obj?.category === "video"
                                                ? convertSecondsToHoursMinutes(
                                                  Obj?.media?.duration.toFixed(
                                                    2
                                                  )
                                                )
                                                : `${Obj?.episodes.length} ${Obj?.episodes.length === 1
                                                  ? "Episode"
                                                  : "Episodes"
                                                }`}
                                            </div>
                                          </div> */}
                                          <div className="block-description ">
                                            {/* <div className="card-slider-content">
                                              {Obj?.category === "movie" ||
                                                Obj?.category === "video" ? (
                                                <h6 className="iq-title title-desc">
                                                

                                                  {currentSelectedLanguage !== "fr" ? Obj?.media?.translated_content?.find(e =>  e.language_code === "en")?.title_translated : Obj?.media?.title } ;
                                                </h6>
                                              ) : (
                                                <h6 className="iq-title title-desc">
                                                   {currentSelectedLanguage !== "fr" ? Obj?.translated_content?.find(e =>  e.language_code === "en")?.title_translated : Obj?.title } ;
                                                </h6>
                                              )}
                                            </div>
                                            <div className="card-slider-desc">
                                              <span
                                                dangerouslySetInnerHTML={{
                                                  __html: limitTextToWords(
                                                    currentSelectedLanguage !== "fr" ? Obj?.media?.translated_content?.find(e =>  e.language_code === "en")?.description_translated : Obj?.media?.description ,
                                                    12
                                                  ),
                                                }}
                                              ></span>
                                            </div> */}
                                            <div className="block-social-info-button">
                                              <li className="share">
                                                <div className="hover-buttons">
                                                  {Obj?.category === "movie" ||
                                                    Obj?.category === "video" ? (
                                                    <Link
                                                      to={`/videos/${Obj?.slug}`}
                                                      className="btn btn-hover iq-button"
                                                    >
                                                      <i
                                                        className="fa fa-play mr-2"
                                                        aria-hidden="true"
                                                      ></i>
                                                      {t("Play now")}
                                                    </Link>
                                                  ) : (
                                                    <Link
                                                      to={`/show-info/${Obj?._id}`}
                                                      className="btn btn-hover iq-button"
                                                    >
                                                      <i
                                                        className="fa fa-play mr-2"
                                                        aria-hidden="true"
                                                      ></i>
                                                      {t("Play now")}
                                                    </Link>
                                                  )}
                                                </div>
                                              </li>
                                            </div>
                                          </div>
                                          <div className="block-social-info">
                                            <ul className="list-inline p-0 m-0 music-play-lists">
                                              <li className="share">
                                                <span>
                                                  <i className="ri-share-fill"></i>
                                                </span>
                                                <div className="share-box">
                                                  <div className="d-flex align-items-center">
                                                    <a
                                                      href={genreFacebookShareUrl(
                                                        Obj,
                                                        
                                                        Obj?.category,
                                                        Obj?.slug
                                                      )}
                                                      onClick={
                                                        handleAnchorClick
                                                      }
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="share-ico"
                                                      tabIndex="0"
                                                    >
                                                      <i className="ri-facebook-fill"></i>
                                                    </a>
                                                    <a
                                                      href={genreTwitterShareUrl(
                                                        Obj,
                                                        Obj?.category,
                                                        Obj?.slug
                                                      )}
                                                      onClick={
                                                        handleAnchorClick
                                                      }
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="share-ico"
                                                      tabIndex="0"
                                                    >
                                                      <i className="ri-twitter-fill"></i>
                                                    </a>
                                                    <a
                                                      href={genreWhatsappShareUrl(
                                                        Obj,
                                                        Obj?.category,
                                                        Obj?.slug
                                                      )}
                                                      onClick={
                                                        handleAnchorClick
                                                      }
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="share-ico iq-copy-link"
                                                      tabIndex="0"
                                                    >
                                                      <i className="ri-whatsapp-fill"></i>
                                                    </a>
                                                    <a
                                                      href={genreLinkedinShareUrl(
                                                        Obj,
                                                        Obj?.category,
                                                        Obj?.slug
                                                      )}
                                                      onClick={
                                                        handleAnchorClick
                                                      }
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="share-ico"
                                                      tabIndex="0"
                                                    >
                                                      <i className="ri-linkedin-fill"></i>
                                                    </a>
                                                    <a
                                                      href={genreInstagramShareUrl(
                                                        Obj,
                                                        Obj?.category,
                                                        Obj?.slug
                                                      )}
                                                      onClick={
                                                        handleAnchorClick
                                                      }
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="share-ico"
                                                      tabIndex="0"
                                                    >
                                                      <i className="ri-instagram-fill"></i>
                                                    </a>
                                                    <a
                                                      href={genreSnapchatShareUrl(
                                                        Obj,
                                                        Obj?.category,
                                                        Obj?.slug
                                                      )}
                                                      onClick={
                                                        handleAnchorClick
                                                      }
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="share-ico"
                                                      tabIndex="0"
                                                    >
                                                      <i className="ri-snapchat-fill"></i>
                                                    </a>
                                                    <a
                                                      href={genreTikTokShareUrl(
                                                        Obj,
                                                        Obj?.category,
                                                        Obj?.slug
                                                      )}
                                                      onClick={
                                                        handleAnchorClick
                                                      }
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="share-ico"
                                                      tabIndex="0"
                                                    >
                                                      <i className="ri-tiktok-fill"></i>
                                                    </a>
                                                    <a
                                                      href={genrePinterestShareUrl(
                                                        Obj,
                                                        Obj?.category,
                                                        Obj?.slug
                                                      )}
                                                      onClick={
                                                        handleAnchorClick
                                                      }
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="share-ico"
                                                      tabIndex="0"
                                                    >
                                                      <i className="ri-pinterest-fill"></i>
                                                    </a>
                                                    <a
                                                      href={genreTelegramShareUrl(
                                                        Obj,
                                                        Obj?.category,
                                                        Obj?.slug
                                                      )}
                                                      onClick={
                                                        handleAnchorClick
                                                      }
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="share-ico"
                                                      tabIndex="0"
                                                    >
                                                      <i className="ri-telegram-fill"></i>
                                                    </a>
                                                    <a
                                                      href={genreRedditShareUrl(
                                                        Obj,
                                                        Obj?.category,
                                                        Obj?.slug
                                                      )}
                                                      onClick={
                                                        handleAnchorClick
                                                      }
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="share-ico"
                                                      tabIndex="0"
                                                    >
                                                      <i className="ri-reddit-fill"></i>
                                                    </a>
                                                    <a
                                                      href={genreQuoraShareUrl(
                                                        Obj,
                                                        Obj?.category,
                                                        Obj?.slug
                                                      )}
                                                      onClick={
                                                        handleAnchorClick
                                                      }
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="share-ico"
                                                      tabIndex="0"
                                                    >
                                                      <i className="ri-quora-fill"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </li>
                                              <li>
                                                <span>
                                                  <i
                                                    className={`ri-heart-fill ${favoriteItems.includes(
                                                      Obj?._id
                                                    )
                                                      ? "red-heart"
                                                      : ""
                                                      }`}
                                                    onClick={(event) =>
                                                      handleFavClick(
                                                        event,
                                                        Obj?._id,
                                                        Obj?.category
                                                      )
                                                    }
                                                    title={
                                                      favoriteItems.includes(
                                                        Obj?._id
                                                      )
                                                        ? t(
                                                          "Remove From Favourite"
                                                        )
                                                        : t("Add To Favourite")
                                                    }
                                                  ></i>
                                                </span>
                                                {/* <span className="count-box">19+</span> */}
                                              </li>
                                              <li>
                                                <span>
                                                  <i
                                                    className={`ri-add-line ${watchListItems.includes(
                                                      Obj?._id
                                                    )
                                                      ? "red-heart"
                                                      : ""
                                                      }`}
                                                    onClick={(event) =>
                                                      handleWatchList(
                                                        event,
                                                        Obj?._id,
                                                        Obj?.category
                                                      )
                                                    }
                                                    title={
                                                      watchListItems.includes(
                                                        Obj?._id
                                                      )
                                                        ? t(
                                                          "Remove From WatchList"
                                                        )
                                                        : t("Add To WatchList")
                                                    }
                                                  ></i>
                                                </span>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>

                                        <div className="d-flex mt-4 justify-content-between align-items-center">
                                    <div className="w-90">
                                        <h6 className="" style={{fontSize : '18px', marginBottom : '0px' , padding : "5px"}}>
                                        {Obj?.category === "movie" ||
                                          Obj?.category === "video" ? (currentSelectedLanguage === 'fr' ? Obj?.media?.title:  Obj?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated ) : Obj.media.title
                                        }
                                          </h6>
                                    </div>
                                    <div className="w-10">
                                    <div className="badge badge-secondary p-1" style={{fontSize : '70%'}}> 
                                    {Obj?.category === "movie" ||
                                          Obj?.category === "video"
                                          ? convertSecondsToHoursMinutes(Obj?.media?.duration.toFixed(2))
                                          : Obj?.category === "ads"
                                            ? null
                                            : `${Obj?.episodes?.length} ${Obj?.episodes?.length === 1
                                              ? "Episode"
                                              : "Episodes"
                                            }`}
                                      </div>
                                    </div>
                                  </div>
                                      </a>
                                    </SwiperSlide>
                                  ))}
                                </Swiper>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          </>
        )}
      </section>
    </>
  );
};
export default VideoDetails;
